const initialState = {
  item: {},
  list: [],
  form_options: {
    clasificaciones_cliente: [],
    clasificaciones_corfo: [],
    directivos: [],
    ejecutivos: [],
    segmentaciones: [],
    ruta_hash: '',
    ruta_documentos: ''
  }
};

const bir = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_BIR':
      return { ...state, item: action.data };
    case 'SET_OPTIONS_BIR':
      return { ...state, form_options: { ...state.form_options, ...action.data } };
    default:
      return { ...state };
  }
};
export default bir;
