import { Ability } from '@casl/ability';
import { initialAbility } from './initialAbility';

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const userData = JSON.parse(localStorage.getItem('userData'));
const existingAbility =
  userData && userData.modulos && userData.modulos.length > 0
    ? userData.modulos.map(modulo => ({ action: 'manage', subject: modulo.id }))
    : null;
export default new Ability(existingAbility || initialAbility);
