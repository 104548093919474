import mock from '../mock'

const data = {
  accountSetting: {
    general: {
      avatar: require('@src/assets/images/portrait/small/avatar-s-11.jpg').default,
      company: 'IBM Technology',
      email: 'granger007@hogward.com',
      fullName: 'John Doe',
      username: 'johndoe',
    },
    info: {
      bio: '',
      country: 'USA',
      dob: null,
      phone: 6562542568,
      website: '',
    },
    notification: {
      answerOnForm: true,
      blogDigest: false,
      commentOnArticle: true,
      followMe: false,
      newAnnouncements: true,
      productUpdates: true,
    },
    social: {
      connections: {
        facebook: {},
        github: {},
        google: {
          id: 'luraweber',
          profileImg: require('@src/assets/images/avatars/3-small.png').default,
        },
        twitter: {
          id: 'johndoe',
          profileImg: require('@src/assets/images/avatars/11-small.png').default,
        },
      },
      socialLinks: {
        facebook: '',
        google: '',
        instagram: '',
        linkedIn: 'https://www.linkedin.com',
        quora: '',
        twitter: 'https://www.twitter.com',
      },
    }
  }
}

mock.onGet('/account-setting/data').reply(() => [200, data.accountSetting])
