import _uniqBy from 'lodash/uniqBy';

const initialState = {
  list_transferencias: [],
  modalDescuentos: false,
  openedModalDescuentos: false,
  pagare: { pagarevista: '' },
  cargos_alternativos: [],
  cargos_obligatorios: [],
  fogain_options: {
    coberturas_fogain: []
  },
  form_options: {
    habilitados: [],
  }
};

const descuentos = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_MODAL_DESCUENTOS':
      return { ...state, modalDescuentos: action.data };
    case 'SET_OPENED_MODAL_DESCUENTOS':
      return { ...state, openedModalDescuentos: action.data };
    case 'SET_PAGARE':
      return { ...state, pagare: action.data };
    case 'SET_CARGOS_OBLIGATORIOS':
      return { ...state, cargos_obligatorios: action.data };
    case 'SET_CARGOS_ALTERNATIVOS':
      return { ...state, cargos_alternativos: action.data };
    case 'SET_TRANSFERENCIAS':
      return { ...state, list_transferencias: action.data };
    case 'SET_BANCOS_CTACTE':
      return {
        ...state,
        list_transferencias: state.list_transferencias.map(transferencia => {
          if (transferencia.identifier === action.data.transferenciaId) {
            return {
              ...transferencia,
              list_bancos: _uniqBy(action.data.bancos_and_ctacte, 'banco').map(item => ({
                value: item.banco,
                label: item.banco_nombre,
              })),
              list_ctacte: action.data.bancos_and_ctacte.map(item => ({
                value: item.ctacte,
                label: item.ctacte,
                banco: item.banco
              })),
            };
          }
          return transferencia;
        }),
      };
    case 'SET_OPTIONS_DESCUENTOS':
      return { ...state, form_options: { ...state.form_options, ...action.data } };
    case 'SET_FOGAIN_OPTIONS_DESCUENTOS':
      return { ...state, fogain_options: { ...state.fogain_options, ...action.data } };
    default:
      return { ...state };
  }
};

export default descuentos;
