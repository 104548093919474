const initialState = {
  loading: false,
  prorroga_id: null,
  prorrogas: [],
  item: {},
  giro: false,
  form_options: {
    sucursales: [],
    payment_options: [],
    docu_cuota_options: [],
    pagare_options: [],
    feriados: [],
    ruta_has: [],
    ruta_documentos: [],
    bancos: [],
  },
};

const prorroga = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADING_PRORROGA':
      return { ...state, loading: action.data };
    case 'SET_PRORROGAS':
      return { ...state, prorrogas: action.data };
    case 'SET_PRORROGA':
      return { ...state, item: action.data };
    case 'SET_OPTIONS_PRORROGA':
      return { ...state, form_options: { ...state.form_options, ...action.data } };
    case 'SET_GIRO_PRORROGA':
      return { ...state, giro: action.data };
    default:
      return { ...state };
  }
};
export default prorroga;
