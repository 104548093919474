// ** Initial State
const initialState = {
  item: {},
  list: [],
  step: 1,
  form_options: {
    beneficiarios: [],
    tipos_garantia: [],
    tipos_garantia_sobre: [],
    pagares: [],
    pagarevistas: [],
    tipos_inmueble: [],
    periodos_prima: [],
    tipos_constitucion: [],
    tipos_bien_raiz: [],
    grados_constitucion: [],
    tipos_mueble_prendaria: [],
    tipos_vehiculo: [],
    tipos_mueble_agraria: [],
    tipos_mueble_industrial: [],
  },
};

const creditos = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ITEM_GARANTIA':
      return { ...state, item: action.data };
    case 'SET_STEP_FORM_GARANTIA':
      return { ...state, step: action.data };
    case 'SET_OPTIONS_GARANTIA':
      return { ...state, form_options: { ...state.form_options, ...action.data } };
    case 'CLEAR_OPTIONS_GARANTIA':
      return {
        ...state,
        form_options: {
          beneficiarios: [],
          tipos_garantia: [],
          tipos_garantia_sobre: [],
          pagares: [],
          pagarevistas: [],
          tipos_inmueble: [],
          periodos_prima: [],
          tipos_constitucion: [],
          tipos_bien_raiz: [],
          grados_constitucion: [],
          tipos_mueble_prendaria: [],
          tipos_vehiculo: [],
          tipos_mueble_agraria: [],
          tipos_mueble_industrial: [],
        },
      };
    default:
      return { ...state };
  }
};

export default creditos;
