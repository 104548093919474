const initialState = {
  options: {
    sucursales: [],
    comunas: [],
    regiones: [],
    estados_causa: [],
    abogados: [],
    procuradores: [],
    clasificaciones_cuantia: [],
    tribunales: [],
    codigos_cierre: [],
    codigos_inactiva: [],
    codigos_procedimiento: [],
    codigos_tipo_causa: [],
    demandante: '',
    tipos_vinculo: [],
  },
  isLoading: false,
  step: 1,
  detalle: {},
};

const judicial = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_JUDICIAL_OPTIONS':
      return { ...state, options: { ...state.options, ...action.data } };
    case 'SET_JUDICIAL_LOADING':
      return { ...state, isLoading: action.data };
    case 'SET_JUDICIAL_STEP':
      return { ...state, step: action.data };
    case 'SET_JUDICIAL_CARATULA_DETALLE':
      return { ...state, detalle: action.data };
    default:
      return { ...state };
  }
};
export default judicial;
