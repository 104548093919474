// ** Redux Imports
import { combineReducers } from 'redux';
// ** Reducers Imports
import calendar from '@src/views/apps/calendar/store/reducer';
import chat from '@src/views/apps/chat/store/reducer';
import ecommerce from '@src/views/apps/ecommerce/store/reducer';
import email from '@src/views/apps/email/store/reducer';
import invoice from '@src/views/apps/invoice/store/reducer';
import todo from '@src/views/apps/todo/store/reducer';
import users from '@src/views/apps/user/store/reducer';
import dataTables from '@src/views/tables/data-tables/store/reducer';
import analisisCliente from './analisis-cliente';
import analista from './analista-virtual';
import ausencias from './ausencias';
import auth from './auth';
import avales from './avales';
import bir from './bir';
import clientes from './clientes';
import cobranzas from './cobranzas';
import creditos from './creditos';
import descuentos from './descuentos';
import deuda from './deuda';
import dicom from './dicom';
import documentos from './documentos';
import ejecutivos from './ejecutivos';
import layout from './layout';
import menus from './menus';
import navbar from './navbar';
import operaciones from './operaciones';
import contratos from './contratos';
import pacs from './pacs';
import pagares from './pagares';
import perfiles from './perfiles';
import proformas from './proformas';
import prorrogas from './prorrogas';
import solicitud from './solicitud';
import judicial from './judicial';
import zonaOperativa from './zonas-operativas';
import consultaConsolidada from './consulta-consolidada';
import prolongaciones from './prolongaciones';
import garantias from './garantias';

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  ejecutivos,
  perfiles,
  menus,
  pagares,
  proformas,
  clientes,
  avales,
  ausencias,
  creditos,
  descuentos,
  bir,
  prorrogas,
  solicitud,
  documentos,
  analisisCliente,
  cobranzas,
  operaciones,
  contratos,
  analista,
  judicial,
  prolongaciones,
  zonaOperativa,
  pacs,
  consultaConsolidada,
  garantias,
  // TEMPLATE
  todo,
  chat,
  dicom,
  deuda,
  email,
  users,
  invoice,
  calendar,
  ecommerce,
  dataTables,
});

export default rootReducer;
