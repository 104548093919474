const initialState = {
  item: {},
  list: [],
  loading: false,
  step: 1,
  form_options: {}
};

const analisisCliente = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ANALISIS_CLIENTE':
      return { ...state, item: action.data };
    case 'SET_STEP_FORM_ANALISIS_CLIENTE':
      return { ...state, step: action.data };
    case 'SET_OPTIONS_ANALISIS_CLIENTE':
      return { ...state, form_options: { ...state.form_options, ...action.data } };
    case 'CLEAR_OPTIONS_ANALISIS_CLIENTE':
      return {...state, form_options: {}};
    case 'SET_LOADING_ANALISIS_CLIENTE':
      return {...state, loading: action.data};
    default:
      return { ...state };
  }
};
export default analisisCliente;
