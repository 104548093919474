const initialState = {
  loading: false,
  form_options: {
    pagare_options: [],
    docu_cuota_options: []
  },
  error: false
};

const analista = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_OPTIONS':
      return { ...state, form_options: { ...state.form_options, ...action.data } };
    case 'CLEAR_OPTIONS_ANALISIS_CLIENTE':
      return {
        ...state, form_options: {
          pagare_options: [],
          docu_cuota_options: []
        }
      };
    case 'SET_ANALISTA_ERROR':
      return { ...state, error: action.data };
    case 'SET_ANALISTA_LOADING':
      return { ...state, loading: action.data };
    default:
      return { ...state };
  }
};
export default analista;
