// ** Initial State
const initialState = {
  item: {},
  list: [],
  loading: false,
  step: 1,
  validaciones_giro_solicitud: null,
  form_options: {
    tope_diario: 0,
    monto_disponible: 0,
  },
  button_autorizar: false,
  modal_confirm_giro: false,
};

const operaciones = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_OPTIONS_OPERACIONES':
      return { ...state, form_options: action.data };
    case 'SET_STEP_OPERACIONES':
      return { ...state, step: action.data };
    case 'SET_VALIDATIONS_GIRO_SOLICITUD':
      return { ...state, validaciones_giro_solicitud: action.data };
    case 'SET_IS_BUTTON_AUTORIZAR':
      return { ...state, button_autorizar: action.data };
    case 'SET_MODAL_CONFIRM_GIRO':
      return { ...state, modal_confirm_giro: action.data };
    default:
      return { ...state };
  }
};
export default operaciones;
