// ** Initial State
const initialState = {
  loading: false,
  submmited: false,
  modalhistorico: false,
  modalconsultas: false,
  form: {
  },
  item: {},
  error:false
};

const deuda = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADING_DEUDA':
      return { ...state, loading: action.data };
    case 'SET_DEUDA':
      return { ...state, item: action.data };
    case 'SET_H_MODAL':
      return { ...state, modalhistorico: action.data };
    case 'SET_C_MODAL':
      return { ...state, modalconsultas: action.data };
    case 'SET_ERROR':
      return { ...state, error: action.data };
    default:
      return { ...state };
  }
};
export default deuda;
