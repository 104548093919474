// ** Initial State
const initialState = {
  item: {},
  list: [],
  form_options: {
    empresas: [],
    zonas: [],
    clases: [],
    reemplazantes: [],
    cargos: [],
    perfiles: [],
    sucursales: []
  },
}

const ejecutivos = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LIST_EJECUTIVOS':
      return { ...state, list: action.data }
    case 'SET_EJECUTIVO':
      return { ...state, item: action.data }
    case 'ADD_EJECUTIVO':
      return { ...state, list: [...state.list, action.data] }
    case 'SET_OPTIONS_EJECUTIVO':
      return { ...state, form_options: {...state.form_options, ...action.data} }
    default:
      return { ...state }
  }
}
export default ejecutivos
