// ** Initial State
const initialState = {
  item: {},
  list_cuotas: [],
  mode_calculate: false,
  apply_descuentos: false,
  open_print_documents: false,
  reset_cuotas: false,
  segmento_cliente: '',
  form_options: {
    sedes: [],
    ejecutivos: [],
    productos: [],
    origenes: [],
    destinos: [],
    costos_operacionales: [],
    plazo_minimo: 1,
    plazo_maximo: 9999,
    valor_uf: 0,
    dias_minimo_vencimiento: 0,
    dias_maximo_vencimiento: 0,
    tasa_impuesto: { tasa_minima: 0, tasa_maxima: 0 },
    periodos_maximo: 0,
    costo_minimo: 0,
    pactos_sin_costo_minimo: [],
    tipos_credito: [],
    fecha_maxima_uf: '',
    ruta_hash: '',
    ruta_documentos: '',
    documentos_pyme: '',
    medios_pago: [],
    tipos_serviu_y_bono_riego: [],
    loading: false,
  },
};

const creditos = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ITEM_CREDITO':
      return { ...state, item: action.data };
    case 'SET_OPTIONS_CREDITO':
      return { ...state, form_options: { ...state.form_options, ...action.data } };
    case 'CLEAR_OPTIONS_CREDITO':
      return {
        ...state,
        form_options: {
          sedes: [],
          ejecutivos: [],
          productos: [],
          origenes: [],
          destinos: [],
          costos_operacionales: [],
          plazo_minimo: 1,
          plazo_maximo: 9999,
          valor_uf: 0,
          dias_minimo_vencimiento: 0,
          dias_maximo_vencimiento: 0,
          tasa_impuesto: { tasa_minima: 0, tasa_maxima: 0 },
          periodos_maximo: 0,
          tipos_credito: [],
          costo_minimo: 0,
          pactos_sin_costo_minimo: [],
          medios_pago: [],
          tipos_serviu_y_bono_riego: [],
        },
      };
    case 'SET_MODE_CALCULATE':
      return { ...state, mode_calculate: action.data };
    case 'SET_APPLY_DESCUENTOS':
      return { ...state, apply_descuentos: action.data };
    case 'SET_CUOTAS':
      return { ...state, list_cuotas: action.data };
    case 'SET_OPEN_PRINT_DOCUMENTS':
      return { ...state, open_print_documents: action.data };
    case 'RESET_CUOTAS':
      return { ...state, reset_cuotas: action.data };
    case 'SEGMENTO_CLIENTE_CREDITO':
      return { ...state, segmento_cliente: action.data };
    case 'SET_LOADING_CREDITO':
      return { ...state, form_options: { ...state.form_options, loading: action.data } };

    default:
      return { ...state };
  }
};

export default creditos;
