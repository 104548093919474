// ** Initial State
const initialState = {
  item: {},
  list: [],
  loading: false,
  form_options: {},
};

const avales = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADING_AVAL':
      return { ...state, loading: action.data };
    case 'SET_LIST_AVALES':
      return { ...state, list: action.data };
    case 'SET_AVAL':
      return { ...state, item: action.data };
    case 'ADD_AVAL':
      return { ...state, list: [...state.list, action.data] };
    default:
      return { ...state };
  }
};
export default avales;
