const initialState = {
  item: {},
  list: [],
  loading: false,
  modalZonaOperativa: false,
  form_options: {
    zonas_operativas: [],
    ejecutivos: [],
    sucursales: [],
  },
};

const zonaOperativa = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_OPTIONS_ZONA_OPERATIVA':
      return { ...state, form_options: { ...state.form_options, ...action.data } };
    case 'SET_MODAL_ZONA_OPERATIVA':
      return { ...state, modalZonaOperativa: action.data };
    case 'SET_LOADING_ZONA_OPERATIVA':
      return { ...state, loading: action.data };
    default:
      return { ...state };
  }
};

export default zonaOperativa;
