const initialState = {
  step: 1,
  filter_options: {
    rechazos: [],
  },
  cuentas: [],
};

const pacs = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_OPTIONS':
      return {
        ...state,
        step: 1,
        filter_options: {
          rechazos: [],
        },
      };
    case 'SET_STEP_PACS':
      return { ...state, step: action.data };
    case 'SET_OPTIONS_PACS':
      return { ...state, filter_options: { ...state.filter_options, ...action.data } };
    case 'SET_CUENTAS_PACS':
      return { ...state, cuentas: action.data };      
    default:
      return { ...state };
  }
};

export default pacs;
