const initialState = {
  item: {},
  list: [],
  loading: false,
  step: 1,
  form_options: {},
  form_filters_options: {
    estados_ticket: [],
    estados_verificacion: [],
    estados_notificacion: [],
    estados_recaudacion: [],
    estados_seguimiento: [],
    operadores_cobranzas: [],
    sucursales: [],
    tipo_documentos: [],
    divisiones: [],
  },
  form_crear_contacto_options: {
    ciudades: [],
    regiones: [],
    comunas: [],
  },
  form_notificacion_options: {
    contactos: [],
  },
  form_ruta_options: {
    contactos: [],
    recaudadores: [],
  },
  form_verificacion_options: {
    contactos: [],
    sub_estados: [],
    estados: [],
  },
  form_call_service_options: {
    contactos: [],
    estados_seguimiento: [],
    estados_llamada: [],
  },
  form_recaudacion_options: {
    metodos_pago: [],
    cuentas_bancos: [],
    contactos: [],
  },
  form_preguntas_options: {
    verificaciones_por: [],
    verificaciones_post_giro: [],
  },
  feriados: {},
  open_modal_asignar_ticket: false,
  item_contacto: null,
  open_modal_preguntas: false,
  reload: true,
  reload_section_1: false,
  reload_section_2: false,
  reload_section_3: false,
  reload_section_4: false,
  preguntas: '',
  reload_maestro_deudor: '',
  filters_params_verificacion: {},
  filters_params_notificacion: {},
  filters_params_carga_diaria: {},
  filters_params_vencimiento: {},
  contactos_use_in_edit: true,
  ticket_selected_data: null,
  ticket_id_call_service_historial: null,
  ticket_id_ruta_recaudacion_historial: null,
  check_notificacion_verificacion: false,
  call_service_modal_open: false,
  reload_call_service_historial: false,
  ticketsSelected: [],
  areFiltersCollapsed: false,
};

const cobranzas = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COBRANZAS':
      return { ...state, item: action.data };
    case 'SET_STEP_FORM_COBRANZAS':
      return { ...state, step: action.data };
    case 'SET_OPTIONS_COBRANZAS':
      return { ...state, form_options: { ...state.form_options, ...action.data } };
    case 'SET_OPTIONS_COBRANZAS_FILTERS':
      return { ...state, form_filters_options: action.data };
    case 'SET_OPTIONS_COBRANZAS_CREAR_CONTACTO':
      return { ...state, form_crear_contacto_options: action.data };
    case 'SET_OPTIONS_COBRANZAS_NOTIFICACION':
      return { ...state, form_notificacion_options: action.data };
    case 'SET_OPTIONS_COBRANZAS_RUTA':
      return { ...state, form_ruta_options: action.data };
    case 'SET_OPTIONS_COBRANZAS_VERIFICACION':
      return { ...state, form_verificacion_options: action.data };
    case 'SET_OPTIONS_COBRANZAS_CALL_SERVICE':
      return { ...state, form_call_service_options: action.data };
    case 'SET_OPTIONS_COBRANZAS_RECAUDACION':
      return { ...state, form_recaudacion_options: action.data };
    case 'SET_OPTIONS_COBRANZAS_PREGUNTAS':
      return { ...state, form_preguntas_options: action.data };
    case 'SET_FERIADOS':
      return { ...state, feriados: action.data };
    case 'CLEAR_OPTIONS_COBRANZAS':
      return {
        ...state,
        step: 1,
        form_options: {},
        form_filters_options: {
          estados_ticket: [],
          estados_verificacion: [],
          estados_notificacion: [],
          estados_recaudacion: [],
          estados_seguimiento: [],
          operadores_cobranzas: [],
          sucursales: [],
          tipo_documentos: [],
        },
        form_crear_contacto_options: {
          ciudades: [],
          regiones: [],
          comunas: [],
        },
        form_notificacion_options: {
          contactos: [],
        },
        form_ruta_options: {
          contactos: [],
          recaudadores: [],
        },
        form_verificacion_options: {
          contactos: [],
          sub_estados: [],
          estados: [],
        },
        form_call_service_options: {
          contactos: [],
          estados_seguimiento: [],
          estados_llamada: [],
        },
        form_recaudacion_options: {
          medios_pagos: [],
          cuentas_bancarias: [],
          contactos: [],
        },
        form_preguntas_options: {
          verificaciones_por: [],
          verificaciones_post_giro: [],
        },
        feriados: {},
        preguntas: '',
        filters_params_verificacion: {},
        filters_params_notificacion: {},
        filters_params_carga_diaria: {},
        filters_params_vencimiento: {},
        open_modal_asignar_ticket: false,
        open_modal_crear_contacto: false,
        ticket_selected_data: null,
        ticket_id_call_service_historial: null,
        ticket_id_ruta_recaudacion_historial: null,
        item_contacto: null,
        open_modal_preguntas: false,
      };
    case 'SET_LOADING_COBRANZAS':
      return { ...state, loading: action.data };
    case 'SET_OPEN_MODAL_ASIGNAR_TICKET':
      return { ...state, open_modal_asignar_ticket: action.data };
    case 'SET_OPEN_MODAL_CREAR_CONTACTO':
      return { ...state, item_contacto: action.data };
    case 'SET_OPEN_MODAL_PREGUNTAS':
      return { ...state, open_modal_preguntas: action.data };
    case 'SET_RELOAD':
      return { ...state, reload: action.data };
    case 'SET_RELOAD_SECTION_1':
      return { ...state, reload_section_1: action.data };
    case 'SET_RELOAD_SECTION_2':
      return { ...state, reload_section_2: action.data };
    case 'SET_RELOAD_SECTION_3':
      return { ...state, reload_section_3: action.data };
    case 'SET_RELOAD_SECTION_4':
      return { ...state, reload_section_4: action.data };
    case 'SET_PREGUNTAS':
      return { ...state, preguntas: action.data };
    case 'SET_RELOAD_MAESTRO_DEUDOR':
      return { ...state, reload_maestro_deudor: action.data };
    case 'SET_FILTERS_PARAMS_VERIFICACION':
      return { ...state, filters_params_verificacion: action.data };
    case 'SET_FILTERS_PARAMS_NOTIFICACION':
      return { ...state, filters_params_notificacion: action.data };
    case 'SET_FILTERS_PARAMS_CARGA_DIARIA':
      return { ...state, filters_params_carga_diaria: action.data };
    case 'SET_FILTERS_PARAMS_VENCIMIENTO':
      return { ...state, filters_params_vencimiento: action.data };
    case 'SET_TICKET_SELECTED_DATA':
      return { ...state, ticket_selected_data: action.data };
    case 'SET_CONTACTOS_USE_IN_EDIT':
      return { ...state, contactos_use_in_edit: action.data };
    case 'SET_TICKET_ID_CALL_SERVICE_HISTORIAL':
      return { ...state, ticket_id_call_service_historial: action.data };
    case 'SET_TICKET_ID_RUTA_RECAUDACION_HISTORIAL':
      return { ...state, ticket_id_ruta_recaudacion_historial: action.data };
    case 'SET_CHECK_NOTIFICACION_VERIFICACION':
      return { ...state, check_notificacion_verificacion: action.data };
    case 'SET_CALL_SERVICE_MODAL_OPEN':
      return { ...state, call_service_modal_open: action.data };
    case 'SET_RELOAD_CALL_SERVICE_HISTORIAL':
      return { ...state, reload_call_service_historial: action.data };
    case 'SET_TICKETS_SELECTED':
      return { ...state, ticketsSelected: action.data };
    case 'SET_ARE_FILTERS_COLLAPSED':
      return { ...state, areFiltersCollapsed: action.data };
    default:
      return { ...state };
  }
};
export default cobranzas;
