import _uniqBy from 'lodash/uniqBy';

const initialState = {
  item: {},
  list: [],
  step: 1,
  itemSelected: null,
  form_options: {
    vinculos: [],
    tipos_contribuyente: [],
    naciones: [],
    estado_civiles: [],
    origenes: [],
    reseñas: [],
    tipos_renta: [],
    ejecutivos: [],
    sectores: [],
    regiones: [],
    comunas: [],
    actividades: [],
    bancos: [],
    giros: [],
    motivos_bloqueo: [],
    habilitado_estados: [],
    tipos_sociedad: [],
    tipos_registro: [],
    abogados: [],
    causales_autoriza: [],
    causales_objeta: [],
    causales_reparo: [],
    vigencia_dias: 0,
    ruta_hash: '',
    ruta_documentos: '',
  },
  habilitados_options: [],
  codlinea: null,
  modalProtesal: false,
  modalDoccobsal: false,
  modalCarcasmo: false,
  perfiles_bloqueo: [],
  perfiles_alertado: [],
};

const clientes = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_CLIENTE':
      return { ...state, item: action.data };
    case 'SET_STEP_FORM_CLIENTE':
      return { ...state, step: action.data };
    case 'SET_OPTIONS_CLIENTE':
      return { ...state, form_options: { ...state.form_options, ...action.data } };
    case 'SET_BANCOS_CTACTE_HABILITADOS':
      const habilitadoExist = state.habilitados_options.find(
        ({ habilitadoId }) => habilitadoId === action.data.habilitadoId
      );
      if (habilitadoExist) {
        return {
          ...state,
          habilitados_options: state.habilitados_options.map(habilitado => {
            if (habilitado.habilitadoId === action.data.habilitadoId) {
              return {
                ...habilitado,
                list_bancos: _uniqBy(action.data.bancos_and_ctacte, 'banco').map(item => ({
                  value: item.banco,
                  label: item.banco_nombre,
                })),
                list_ctacte: action.data.bancos_and_ctacte.map(item => ({
                  value: item.ctacte,
                  label: item.ctacte,
                  banco: item.banco,
                })),
              };
            }
            return habilitado;
          }),
        };
      }
      return {
        ...state,
        habilitados_options: [
          ...state.habilitados_options,
          {
            habilitadoId: action.data.habilitadoId,
            list_bancos: _uniqBy(action.data.bancos_and_ctacte, 'banco').map(item => ({
              value: item.banco,
              label: item.banco_nombre,
            })),
            list_ctacte: action.data.bancos_and_ctacte.map(item => ({
              value: item.ctacte,
              label: item.ctacte,
              banco: item.banco,
            })),
          },
        ],
      };
    case 'SET_ITEM_SELECTED':
      return {
        ...state,
        itemSelected: action.data,
      };
    case 'CLEAR_OPTIONS_CLIENTE':
      return {
        ...state,
        step: 1,
        item: {},
        form_options: {
          vinculos: [],
          tipos_contribuyente: [],
          naciones: [],
          estado_civiles: [],
          origenes: [],
          reseñas: [],
          tipos_renta: [],
          ejecutivos: [],
          sectores: [],
          regiones: [],
          comunas: [],
          actividades: [],
          bancos: [],
          giros: [],
          motivos_bloqueo: [],
          habilitado_estados: [],
          tipos_sociedad: [],
          tipos_registro: [],
          abogados: [],
          causales_autoriza: [],
          causales_objeta: [],
          causales_reparo: [],
          motivos_retencion: [],
          vigencia_dias: 0,
        },
        habilitados_options: [],
      };
    case 'SET_MODAL_DETALLE_LINEA':
      return { ...state, codlinea: action.data };
    case 'SET_MODAL_PROTESAL':
      return { ...state, modalProtesal: action.data };
    case 'SET_MODAL_DOCCOBSAL':
      return { ...state, modalDoccobsal: action.data };
    case 'SET_MODAL_CARCASMO':
      return { ...state, modalCarcasmo: action.data };
    case 'SET_PERFILES_BLOQUEO':
      return { ...state, perfiles_bloqueo: action.data };
    case 'SET_PERFILES_ALERTADO':
      return { ...state, perfiles_alertado: action.data };

    default:
      return { ...state };
  }
};
export default clientes;
