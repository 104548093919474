// ** Initial State
const initialState = {
  loading: false,
  submmited: false,
  modal: false,
  form: {
  },
  dicom: {

  },
  error: false
};

const dicom = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOADING_PAGARE':
      return { ...state, loading: action.data };
    case 'SET_DICOM':
      return { ...state, dicom: action.data };
    case 'SET_DICOM_MODAL':
      return { ...state, modal: action.data };
    case 'SET_ERROR':
      return { ...state, error: action.data };
    default:
      return { ...state };
  }
};
export default dicom;
