// ** Initial State
const initialState = {
  item: {},
  list: [],
  file: null,
  form_options: {
    tipos: [],
    parametros: [],
  },
};

const creditos = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_ITEM_DOCUMENTO':
      return { ...state, item: action.data };
    case 'SET_OPTIONS_DOCUMENTO':
      return { ...state, form_options: { ...state.form_options, ...action.data } };
    case 'SET_FILE':
      return { ...state, file: action.data };

    default:
      return { ...state };
  }
};

export default creditos;
