const initialState = {
  num_proforma: '',
  item: {},
  loading_table: false,
  list_documents: [],
  active_form: true,
  cuotas: [],
  generar_cuotas: false,
  clicked_button_calcular: false,
  recalculate_documents: false,
  costo_dia: {},
  openedModalCuotas: false,
  extra_data: {
    numero: null,
    rutEmisor: null,
    cantidad: null,
    fechacurso: null,
    detalle: null,
  },
  form_options: {
    ayuda_bancos: [],
    costo_minimo: [],
    productos: [],
    ejecutivos: [],
    origen_de_operacion: [],
    destino_de_la_operacion: [],
    tipo_de_la_operacion: [],
    dte: [],
    sedes: [],
    pagare_o_contratos: [],
    pactos_sin_costo_minimo: [],
    descuentos_proforma: [],
    ruta_hash: '',
    ruta_documentos: '',
    dias_extra_femision: 0,
    minimo_dias_mypime: 0,
    maximo_dias_mypime: 0,
    minimo_descontado: 0,
    valor_uf: 0,
    maximo_uf_concentracion: 0,
    restriccion_tml: 0,
    pactos_with_contrato: [],
  },
};

const proformas = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PROFORMA_NUM':
      return { ...state, num_proforma: action.data };
    case 'SET_LOADING_TABLE':
      return { ...state, loading_table: action.data };
    case 'SET_COSTO_DIA':
      return { ...state, costo_dia: action.data };
    case 'SET_CUOTAS':
      return { ...state, cuotas: action.data };
    case 'SET_GENERAR_CUOTAS':
      return { ...state, generar_cuotas: action.data };
    case 'SET_RECALCULATE_DOCUMENTS':
      return { ...state, recalculate_documents: action.data };
    case 'SET_BUTTON_CALCULAR':
      return { ...state, clicked_button_calcular: action.data };
    case 'SET_EXTRA_DATA':
      return { ...state, extra_data: action.data };
    case 'SET_ACTIVE_FORM':
      return { ...state, active_form: action.data };
    case 'ADD_DOCUMENT':
      return { ...state, list_documents: [...state.list_documents, action.data] };
    case 'ADD_CUOTA':
      return { ...state, cuotas: [...state.cuotas, action.data] };
    case 'SET_DOCUMENTS':
      return { ...state, list_documents: action.data };
    case 'REMOVE_DOCUMENT':
      return {
        ...state,
        list_documents: state.list_documents.filter(({ pk }) => pk !== action.data),
      };
    case 'REMOVE_CUOTA':
      return {
        ...state,
        cuotas: state.cuotas.filter(({ pk }) => pk !== action.data),
      };
    case 'UPDATE_CUOTA':
      return {
        ...state,
        cuotas: state.cuotas.map(cuota => {
          return cuota.pk === action.data.pk ? action.data : cuota;
        }),
      };
    case 'UPDATE_DOCUMENT':
      return {
        ...state,
        list_documents: state.list_documents.map(document => {
          return document.pk === action.data.pk ? action.data : document;
        }),
      };
    case 'SET_OPTIONS_PROFORMA':
      return { ...state, form_options: { ...state.form_options, ...action.data } };
    case 'SET_OPENED_MODAL_CUOTAS':
      return { ...state, openedModalCuotas: action.data };
    case 'SET_PROFORMA':
      return { ...state, item: action.data };
    default:
      return { ...state };
  }
};

export default proformas;
