const initialState = {
  form_options: {
    contratos_monto_fijo: null,
    contratos_tipos: [],
    contratos_dias_vencimiento: null,
    sucursales: [],
  },
  informe_options: {
    ejecutivos: [],
    sucursales: [],
    contratos_tipos: [],
  },
  loading: false,
  disabledRut: false,
};

const contratos = (state = initialState, action) => {
  switch (action.type) {
    case 'CLEAR_OPTIONS_CONTRATOS':
      return {
        ...state,
        form_options: {
          contratos_monto_fijo: null,
          contratos_tipos: [],
          contratos_dias_vencimiento: null,
          sucursales: [],
        },
        informe_options: {
          ejecutivos: [],
          sucursales: [],
          contratos_tipos: [],
        },
        loading: false,
        disabledRut: false,
      };
    case 'SET_OPTIONS_CONTRATOS':
      return { ...state, form_options: { ...state.form_options, ...action.data } };
    case 'SET_OPTIONS_CONTRATOS_INFORMES':
      return { ...state, informe_options: { ...state.informe_options, ...action.data } };
    case 'SET_LOADING':
      return { ...state, loading: action.data };
    case 'SET_DISABLED_RUT':
      return { ...state, disabledRut: action.data };
    default:
      return { ...state };
  }
};
export default contratos;
