const initialState = {
  item: { nombre_sucursal: '', garantias: [] },
  list: [],
  step: 1,
  form_options: {
    excepciones: [],
  },
  virtual_analyst: {
    isLoading: false,
    result: null,
    errorMsg: null,
  },
  submit_precios: false,
};

const solicitud = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_OPTIONS_SOLICITUD':
      return { ...state, form_options: { ...state.form_options, ...action.data } };
    case 'SET_STEP_FORM_SOLICITUD':
      return { ...state, step: action.data };
    case 'SET_ITEM_SOLICITUD':
      return { ...state, item: { ...state.item, ...action.data } };
    case 'SET_AV_LOADING_SOLICITUD':
      return { ...state, virtual_analyst: { ...state.virtual_analyst, isLoading: action.data } };
    case 'SET_AV_RESULT_SOLICITUD':
      return { ...state, virtual_analyst: { ...state.virtual_analyst, result: action.data } };
    case 'SET_AV_ERROR_SOLICITUD':
      return { ...state, virtual_analyst: { ...state.virtual_analyst, errorMsg: action.data } };
    case 'SET_SUBMIT_PRECIOS_SOLICITUD':
      return { ...state, submit_precios: action.data };
    default:
      return { ...state };
  }
};

export default solicitud;
