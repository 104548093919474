// ** Initial State
const initialState = {
  item: {},
  list: [],
  loading: false,
  form_options: {
    menu_parents: [],
    menu_seconds: []
  },
}

const menus = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LIST_MENUS':
      return { ...state, list: action.data }
    case 'SET_LOADING_MENUS':
      return { ...state, loading: action.data }
    case 'SET_PERFIL':
      return { ...state, item: action.data }
    case 'ADD_PERFIL':
      return { ...state, list: [...state.list, action.data] }
    case 'SET_OPTIONS_MENU':
      return { ...state, form_options: {...state.form_options, ...action.data} }
    default:
      return { ...state }
  }
}
export default menus
