// ** Initial State
const initialState = {
  item: {},
  list: [],
  form_options: {
    ejecutivos: [],
    motivos: []
  },
}

const ejecutivos = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LIST_AUSENCIAS':
      return { ...state, list: action.data }
    case 'SET_AUSENCIA':
      return { ...state, item: action.data }
    case 'ADD_AUSENCIA':
      return { ...state, list: [...state.list, action.data] }
    case 'SET_OPTIONS_AUSENCIA':
      return { ...state, form_options: {...state.form_options, ...action.data} }
    default:
      return { ...state }
  }
}
export default ejecutivos
